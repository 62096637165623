@layer components {
  .splide {
    .splide__arrow {
      @apply absolute top-[50%] translate-y-[-50%] z-10 cursor-pointer transition-all;

      &:disabled {
        @apply pointer-events-none opacity-20;
      }

      svg {
        @apply h-8 w-8 transition hover:fill-blue focus:fill-blue;
      }
    }

    .splide__pagination {
      @apply -bottom-[2rem] flex gap-2;
    }

    .splide__pagination__page {
      @apply w-2 h-2 rounded-full bg-grey-300 !important;

      &.is-active {
        @apply bg-blue !important;
      }
    }
  }

  .paragraph--swimlane--nws {
    .splide {
      .splide__arrow {
        @apply max-sm:top-[calc(50%-60px)] max-md:top-[calc(50%-40px)] max-lg:top-[calc(50%-10px)];
      }
    }
  }

  .paragraph--swimlane--sporza {
    .splide {
      .splide__arrow {
        @apply max-sm:top-[calc(50%-35px)] max-md:top-[calc(50%-10px)];
      }
    }
  }

  .item-slider {
    .splide__track {
      @apply overflow-visible !important;
    }

    .splide__arrow {
      @apply sm:opacity-0 w-10 h-10 inline-flex shadow-lg items-center justify-center bg-white shadow-md rounded-lg hover:scale-110 hover:translate-y-[-50%] active:scale-90 active:translate-y-[-50%] origin-center transition-all;

      &[disabled] {
        @apply pointer-events-none opacity-0;
      }

      svg {
        @apply transition-colors;

        backface-visibility: hidden;
        will-change: transform;
      }

      &:focus-visible,
      &:focus {
        &:not([disabled]) {
          @media (pointer: fine), (-ms-high-contrast: none) {
            @apply opacity-80;
          }
        }
      }
    }

    &:hover {
      .splide__arrow {
        &:not([disabled]) {
          @media (pointer: fine), (-ms-high-contrast: none) {
            @apply opacity-80;

            &:hover {
              @apply opacity-100;

              svg {
                @apply fill-blue;
              }
            }
          }
        }
      }
    }
  }
}
