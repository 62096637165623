@layer components {
  body {
    &.landing-page,
    &.subsite-landing-page,
    &.vrt-cards-page {
      @apply lg:!pt-0;

      &:not(.sub-nav-open) {
        .header {
          @apply shadow-none !important;

          > div {
            @apply shadow-none !important;
          }

          &.header-top {
            @apply lg:bg-transparent lg:border-transparent !important;
          }
        }

        .subsite-header {
          &.subsite-header-top {
            @apply lg:bg-transparent lg:border-transparent !important;
          }

          &__inner {
            @apply lg:shadow-none !important;
          }
        }
      }

      .cards-hero,
      .landing-hero {
        @apply lg:pt-[var(--header-height)];

        &.hero-full {
          @apply lg:h-screen !important;
        }
      }

      .landing-hero {
        .landing-hero__cards {
          @apply lg:pt-5 !important;
        }


        &::before {
          @screen lg {
            @apply content-[''] absolute w-full from-transparent via-transparent to-grey-700 bg-gradient-to-t top-0 h-[50vh];
          }
        }
      }
    }

    &.landing-page,
    &.subsite-landing-page {
      &:not(.sub-nav-open) {
        .header {
          &.header-top {
            @apply lg:text-white !important;

            .main-nav-item,
            .main-nav-button {
              @apply lg:text-white;
            }
          }
        }

        .subsite-header {
          &.subsite-header-top {
            @apply lg:text-white !important;

            .subsite-nav {
              a {
                @apply lg:text-white !important;
              }
            }
          }
        }
      }
    }

    &.subsite-landing-page {
      .cards-hero,
      .landing-hero {
        @apply lg:pt-[calc(2*var(--header-height))];
      }

      .subsite-header.subsite-header-top {
        .subsite-header__inner--w-inverse-logo {
          .subsite-logo--default {
            @apply lg:hidden !important;
          }

          .subsite-logo--inverse {
            @apply lg:block !important;
          }
        }
      }
    }

    &.subsite-page,
    &.subsite-landing-page {
      &.sub-nav-open {
        .subsite-header {
          @apply lg:opacity-0 lg:pointer-events-none transition-none !important;
        }
      }
    }
  }
}
