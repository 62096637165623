@layer components {
  .hero-full {
    @apply lg:h-[calc(100vh-var(--header-height))];
  }

  .landing-hero {
    @screen lg {
      @apply image-overlay;
    }

    &__cards {
      @screen lg {
        @apply overflow-gradient-tb-sm;
      }

      .idk-item {
        @apply aspect-video lg:max-h-[200px] xl:max-h-[300px] lg:shadow-light lg:min-h-[140px];

        &:first-of-type {
          @screen lg {
            @apply lg:mt-auto;
          } 
        }
      }
    }

    &__ctas {
      .btn-primary {
        @screen lg {
          @apply !bg-white !border-white !text-grey hover:!bg-opacity-90;
        }
      }
    }
  }

  .cards-hero {
    .idk-item {
      @apply max-lg:aspect-video  lg:min-h-[30vh] lg:max-h-[300px] lg:shadow-lg;
    }

    &__list {
      &--4 {
        @screen lg {
          .idk-item {
            &:nth-of-type(2) {
              @apply lg:mt-10;
            }
            
            &:nth-of-type(3) {
              @apply lg:-mt-10;
            }
            
            &:nth-of-type(4) {
              @apply lg:-mt-2;
            }
          }
        }
      }
      
      &--3 {
        @screen lg {
          .idk-item {
            &:nth-of-type(2) {
              @apply lg:mt-6;
            }
            
            &:nth-of-type(3) {
              @apply lg:-mt-4;
            }
          }
        }
      }
    }
  }
}
