@layer components {
  .ant-select {
    &-selector {
      @apply form-input rounded-md border-2 !border-grey-700/50 ring-0 text-sm min-h-[50px] !important;
    }

    &-focused {
      @apply !ring-0;

      .ant-select-selector {
        @apply !border-blue;
      }
    }

    &-single {
      @apply h-auto !important;
    }

    &-selection-search-input {
      @apply focus:outline-0 focus:ring-0 ring-0 !important;
    }

    &-selection-search {
      margin-inline-start: 0!important;
    }

    &-selection-placeholder {
      @apply text-grey-700 px-1 !important;
    }

    &-item-option-selected {
      @apply bg-blue text-white !important
    }

    &-item-option-state {
      @apply text-white !important
    }

    &-clear {
      @apply mr-6 !important;
    }
  }
}