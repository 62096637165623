@layer components {
  .vrt-edubox__button-icon {
    &::before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.387 9.503c-1.717 3.6-4.432 6.464-8.055 8.505L.5 16.343c2.888-1.628 5.063-3.728 6.615-6.39l.353-.548a.753.753 0 000-.81L7.13 8.07C5.577 5.4 3.402 3.293.508 1.665L1.34 0c3.63 2.04 6.338 4.905 8.055 8.505.15.308.15.675 0 .99l-.008.008z' fill='%23031037'/%3E%3C/svg%3E");
    }
  }

  .vrt-edubox-wrapper {
    @apply mb-16 lg:mb-20 z-[1] relative;

    .vrt-edubox__menu-item {
      .vrt-edubox__link {
        @apply hover:underline;
      }
    }

    .vrt-edubox__title {
      @apply !leading-[1.45] !mb-[2rem] !text-[30px] lg:!text-[50px];
    }

    .vrt-edubox__subtitle {
      @apply !mb-[1rem] !text-[20px];
    }

    .vrt-edubox__full-box {
      .vrt-edubox__title {
        @apply !text-[30px];
      }
    }

    .grid-x {
      @apply w-full;
    }

    @media print, screen and (min-width: 64em) {
      .grid--edge {
        width: calc(100% + 1.875rem) !important;
      }
    }

    .vrt-edubox__drawing,
    .vrt-edubox__iframe,
    iframe {
      @apply inline-block;
    }

    .vrt-edubox__boxes-item {
      .vrt-edubox__label {
        @apply top-0;
      }
    }

    .vrt-edubox__hero2 {
      .vrt-edubox__subtitle {
        @apply md:!mt-0 !mb-[1rem] !text-[30px] !font-bold;
      }
    }

    .vrt-edubox__hero {
      .vrt-edubox__title {
        @apply md:!mt-0 !mb-[2rem] !text-[35px] !leading-[1.2] lg:!text-[64px];
      }
    }

    .vrt-edubox__testimonial-content,
    .vrt-edubox__event-content {
      .vrt-edubox__title {
        @apply !mb-[0rem] !text-[19px] !font-bold;
      }
    }

    h2 {
      @apply !text-[1.5rem] !font-bold !leading-[1.125];
    }

    .vrt-edubox__title--light {
      @apply !text-[48px] !mb-[1rem] !font-bold !leading-[1.125];
    }

    .vrt-edubox__section--box {
      .vrt-edubox__button-label {
        @apply !border-none;
      }
    }

    .vrt-edubox__testimonial-detail {
      .vrt-edubox__content {
        p {
          @apply mt-0;

          &:not(:last-child) {
            @apply !mb-[1rem] lg:!mb-[1.5rem];
          }
        }

        h1 {
          @apply !text-[2rem] !font-bold !leading-[1.125] !mb-2;
        }
      }
    }
  }
}
