@layer components {
  .main-nav-w-sub {
    span {
      @apply btn py-1 pl-3 pr-2 rounded-full inline-flex items-center text-grey hover:bg-blue hover:text-white;
    }

    &--active {
      span {
        @apply bg-midnight text-white;
      }
    }
  }

  .sub-nav--dark {
    .top-level-nav {
      @apply !text-white transition-colors max-lg:border-white/20;
    }
  }

  .header {
    &--hidden {
      .backdrop-overlay {
        @apply opacity-0 pointer-events-none !important;
      }
    }
  }
}
