.list-checkmarks {
  @apply !pl-0 list-none grid gap-2;

  > li {
    @apply grid grid-cols-[0_1fr] gap-6 items-start !my-0;

    &::before {
      @apply content-[''] w-3 h-[1.75em] bg-current bg-no-repeat bg-contain bg-center;
    }
  }
}
