@layer components {
  .main-search-input {
    @apply py-1 lg:py-2;

    .form-input {
      @apply w-full h-full rounded-full border;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }
  }
}
