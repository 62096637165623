@layer components {
  .select-default {
    @apply font-medium text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;

    background-image: url('data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20version=%221.1%22%20viewBox=%220%200%2020%2020%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23ffffff;%7D%3C/style%3E%3C/defs%3E%3Cg%3E%3Cg%20id=%22Layer_1%22%3E%3Cpath%20class=%22cls-1%22%20d=%22M9.7,12.9c-2.4-1.1-4.3-2.9-5.6-5.3l1.1-.5c1.1,1.9,2.5,3.3,4.2,4.4l.4.2c.2.1.4.1.5,0l.3-.2c1.8-1,3.1-2.5,4.2-4.4l1.1.5c-1.3,2.4-3.2,4.2-5.6,5.3-.2,0-.4,0-.7,0h0Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
  }

  .select-primary {
    @apply bg-grey border border-grey text-white;
  }

  .newsletter-form {
    label {
      @apply !sr-only;
    }

    input[type='text'],
    input[type='email'] {
      @apply bg-gray-50 font-medium border border-gray-300 text-gray-900 rounded-lg focus:ring focus:ring-blue-500 focus:border-blue-500 block w-full p-6 lg:py-7 max-w-full;
    }

    button[type='submit'] {
      @apply flex w-full btn p-4 bg-green text-grey rounded-lg hover:bg-green-300 focus:bg-green-300 hover:text-grey focus:text-grey;
    }

    p {
      &.optinWidget__error,
      &.optinWidget__success {
        @apply !py-1 !px-4 !rounded text-sm animate-[fadeInDown_both_.3s];
      }

      &.optinWidget__error {
        @apply !bg-red !text-white;
      }

      &.optinWidget__success {
        @apply !bg-green !text-white;
      }
    }
  }

  .paragraph--nieuwsbrief {
    .prose-invert {
      a {
        @apply text-white;
      }
    }
  }
}
