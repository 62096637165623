@layer components {
  .sub-nav {
    @apply lg:overflow-y-auto lg:max-h-[calc(100vh-var(--header-height))];

    &__item {
      &--brand-cat {
        @apply lg:w-[20px] relative;

        span {
          @apply max-lg:font-medium text-midnight-100 uppercase text-[.7rem] lg:-rotate-90 lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 tracking-wider lg:tracking-[.12rem] lg:mx-auto lg:text-center whitespace-nowrap;
        }
      }

      &--brand {
        @apply flex flex-col justify-center gap-1;

        img {
          @apply min-w-[4rem] w-[4rem] h-[4rem] transition-transform mx-auto;
        }

        span {
          @apply max-lg:font-medium text-white lg:text-white/80 text-[.8rem] mx-auto transition-colors whitespace-nowrap text-center;
        }

        &:hover {
          img {
            @apply transform scale-105;
          }

          span {
            @apply text-white;
          }
        }
      }

      &--bold-title {
        @apply text-grey;
      }

      &--default-link {
        @apply text-grey;
      }
    }

    .sub-nav-brands {
      @apply w-full lg:pl-8 lg:w-[calc(100%-20px)] flex gap-y-2 gap-x-4 lg:gap-8 overflow-x-auto flex-wrap pb-2 lg:py-2 no-scrollbar;

      img {
        @apply w-[60px] min-w-[60px] aspect-square lg:w-[80px] lg:w-[80px] lg:min-w-[80px];
      }
    }

    .sub-nav-default-nested,
    .sub-nav-default {
      @apply flex flex-col space-y-2;

      >li,
      ul {
        @apply flex flex-col space-y-1;
      }
    }

    .sub-nav-default-nested {
      ul {
        @apply flex flex-col space-y-2;
      }
    }

    .sub-nav-default-nested,
    .sub-nav__item--bold-title,
    .sub-nav__item--brand-cat,
    .sub-nav-brands {
      @apply lg:animate-[fadeInDown_both_.3s] !important;
    }

    &--dark {
      .sub-nav__list {
        @apply flex flex-col lg:flex-row flex-wrap text-white space-y-1;
      }

      .sub-nav__item--bold-title {
        @apply text-white;
      }

      .sub-nav-default-nested,
      .sub-nav-default {
        @apply lg:w-[calc(100%-20px)] lg:ml-auto lg:pl-8;
      }

      .sub-nav-default-nested {
        @apply !mt-6 lg:!mt-6;
      }

      .sub-nav__item--default-link {
        @apply  text-white/80 hover:text-white transition-colors;
      }
    }

    &--default {
      .sub-nav__list {
        @apply max-lg:flex max-lg:flex-col lg:grid lg:grid-cols-4 gap-4 lg:gap-y-6 items-start;
      }
    }
  }
}
