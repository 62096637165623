@layer components {
  .ant-breadcrumb {
    @apply box-border p-0 m-0 text-[13px] lg:text-[14px] leading-[1.2715] lg:leading-[1.5715] !font-sans !text-grey;

    ol {
      @apply flex flex-wrap m-0 p-0 list-none;
    }

    a {
      margin-inline: -4px;

      @apply !text-grey !bg-transparent rounded-none h-[22px] inline-block px-[4px] underline hover:!text-blue hover:underline transition-colors;
    }
  }

  .ant-breadcrumb > span:last-child {
    @apply !text-grey-400;
  }

  .ant-breadcrumb li:last-child .ant-breadcrumb-link {
    @apply !text-midnight;
  }

  .ant-breadcrumb-separator {
    margin-inline: 6px !important;

    @apply !text-midnight-700/40 text-[14px] lg:text-[15px];
  }
}
