@layer components {
    .notification {
        &.red {
            @apply bg-pink-150;
        }

        &.green {
            @apply bg-green-100;
        }

        &.blue {
            @apply bg-blue-100;
        }
    }
}

.notification {
    @apply border-none text-grey rounded-lg p-4 lg:p-6 not-italic font-semibold inline-block w-full;
}
