@layer utlities {
  .stretched-link::after {
    content: "";
    @apply pointer-events-auto absolute inset-0 z-1 bg-transparent;
  }

  .text-balance {
    text-wrap: balance;
  }

  .offset-hero {
    @apply xl:!pt-[330px];
  }

  .offset-header {
    @apply pt-[var(--header-height)];
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .image-overlay {
    @apply relative;

    &::after {
      content: "";
      @apply absolute w-full from-transparent via-transparent to-grey-700;
    }

    &::before {
      @apply bg-gradient-to-t top-0 h-2/6;
    }

    &::after {
      @apply bg-gradient-to-b bottom-0 h-full;
    }
  }

  .image-overlay-lg {
    @apply image-overlay;

    &::before,
    &::after {
      @apply max-lg:!hidden;
    }
  }
  
  .image-overlay-soft {
    @apply relative;

    &::after {
      content: "";
      @apply absolute w-full from-transparent via-transparent to-grey-700/40;
    }

    &::before {
      @apply bg-gradient-to-t top-0 h-2/6;
    }

    &::after {
      @apply bg-gradient-to-b bottom-0 h-full;
    }

    @media (prefers-contrast: more) {
      &::after {
        @apply via-grey-700/40 to-grey-700/90;
      }
    }
  }

  .bg-red-200 {
    .image-overlay {      
      &::after {
        @apply !to-red-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/20;
      }

      table {
        --tw-prose-th-borders: #e62323;
        --tw-prose-td-borders: #ad1e1e;
      }
    }
  }

  .bg-green-200 {
    .image-overlay {
      &::after {
        @apply !to-green-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/30;
      }

      table {
      --tw-prose-th-borders: #165827;
      --tw-prose-td-borders: #21891f;
      }
    }
  }

  .bg-yellow-200 {
    .image-overlay {
      &::after {
        @apply !to-yellow-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/30;
      }

      table {
        --tw-prose-th-borders: #b17500;
        --tw-prose-td-borders: #ff9600;
      }
    }
  }

  .bg-grey-200 {
    .image-overlay {
      &::after {
        @apply !to-grey-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/30;
      }

      table {
        --tw-prose-th-borders: #3f4865;
        --tw-prose-td-borders: #6a788c;
      }
    }
  }

  .bg-blue-200 {
    .image-overlay {
      &::after {
        @apply !to-blue-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/30;
      }

      table {
        --tw-prose-th-borders: #002b6c;
        --tw-prose-td-borders: #005aeb;
      }
    }
  }

  .bg-pink-200 {
    .image-overlay {
      &::after {
        @apply !to-pink-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/20;
      }

      table {
        --tw-prose-th-borders: #960032;
        --tw-prose-td-borders: #e60064;
      }
    }
  }

  .bg-ketnet-200 {
    .image-overlay {
      &::after {
        @apply !to-ketnet-700;
      }
    }

    .prose {
      blockquote {
        @apply !bg-white/20;
      }

      table {
        --tw-prose-th-borders: #002b6c;
        --tw-prose-td-borders: #005aeb;
      }
    }
  }

  .bg-ketnet-600 {
    .newsletter-form button[type='submit'] {
      @apply !bg-ketnet-200 hover:!bg-ketnet-300;
    }
  }

  .bg-blue-600 {
    .newsletter-form button[type='submit'] {
      @apply !bg-blue-300 hover:!bg-blue-400 !text-white;
    }
  }

  .bg-pink-600 {
    .newsletter-form button[type='submit'] {
      @apply !bg-pink-200 hover:!bg-pink-300;
    }
  }

  .bg-grey {
    .newsletter-form button[type='submit'] {
      @apply !bg-grey-400 hover:!bg-grey-500 !text-white;
    }
  }

  .bg-red-600 {
    .newsletter-form button[type='submit'] {
      @apply !bg-red-400 hover:!bg-red-500 !text-white;
    }
  }

  .bg-yellow-600 {
    .newsletter-form button[type='submit'] {
      @apply !bg-yellow-300 hover:!bg-yellow-400;
    }
  }

  .bg-green-600 {
    .newsletter-form button[type='submit'] {
      @apply !bg-green-200 hover:!bg-green-300;
    }
  }

  .overflow-gradient-tb {
    mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 7%, white 93%, rgba(255, 255, 255, 0) 100%);
  }

  .overflow-gradient-tb-sm {
    mask-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 2%, white 98%, rgba(255, 255, 255, 0) 100%);
  }

  .overflow-gradient-x-xl {
    @screen xl {
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 2%, white 98%, rgba(255, 255, 255, 0) 100%);
    }

    @screen 2xl {
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 4%, white 96%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .overflow-gradient-x-sm {
    mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 4%, white 96%, rgba(255, 255, 255, 0) 100%);

    @screen lg {
      mask-image: none;
    }
  }

  .image-scale {
    backface-visibility: hidden;
    will-change: transform;
  }

  .text-align-center {
    @apply text-center;
  }

  .text-align-right {
    @apply text-right;
  }

  .break-free {
    @apply relative left-1/2;

    margin-left: -50vw;
    margin-left: calc(var(--vw, 1vw) * -50);
    width: 100vw;
    width: calc(var(--vw, 1vw) * 100);
  }
}
