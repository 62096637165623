@layer components {
  .font-serif {
    @apply antialiased;
  }

  .font-bold,
  .font-black,
  .font-medium,
  strong,
  b {
    @apply antialiased;
  }
}
